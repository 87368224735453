module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"PlatformJobs.io","short_name":"PlatformJobs","start_url":"/","background_color":"#edc6c5","icon":"static/pwa-512x512.png","theme_color":"#D83B35","display":"standalone"},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-156321092-1"],"pluginConfig":{"head":false}},
    }]
