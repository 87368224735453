// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-job-template-tsx": () => import("../src/templates/JobTemplate.tsx" /* webpackChunkName: "component---src-templates-job-template-tsx" */),
  "component---src-templates-company-template-tsx": () => import("../src/templates/CompanyTemplate.tsx" /* webpackChunkName: "component---src-templates-company-template-tsx" */),
  "component---src-templates-role-types-template-tsx": () => import("../src/templates/RoleTypesTemplate.tsx" /* webpackChunkName: "component---src-templates-role-types-template-tsx" */),
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-companies-tsx": () => import("../src/pages/companies.tsx" /* webpackChunkName: "component---src-pages-companies-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-post-a-job-tsx": () => import("../src/pages/post-a-job.tsx" /* webpackChunkName: "component---src-pages-post-a-job-tsx" */),
  "component---src-pages-salesforce-jobs-in-london-tsx": () => import("../src/pages/salesforce-jobs-in-london.tsx" /* webpackChunkName: "component---src-pages-salesforce-jobs-in-london-tsx" */)
}

